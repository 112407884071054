<template>
  <div class="container">
    <div class="content">
      <span>copyright@2019 www.apumao.vip All Right Reserved </span>
      <span>武汉阿朴猫网络科技有限公司版权所有 </span>
      <ul class="flex-vw flex">
        <li title="鄂ICP备2023023531号-1" class="flex">
          <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023023531号-1</a>
        </li>
        <li title="中国互联网违法和不良信息举报中心" class="flex">
          <a href="https://www.12377.cn/" target="_blank">中国互联网违法和不良信息举报中心</a>
        </li>
        <li title="中国扫黄打非-网上举报" class="flex aTab">
          <a href="https://www.shdf.gov.cn/shdf/channels/740.html" target="_blank">中国扫黄打非-网上举报</a>
          <a href="https://jbts.mct.gov.cn/" target="_blank">12318全国文化市场举报网站</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 2.56rem;
  background: #ebf4ff;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.32rem;
    span {
      font-weight: 400;
      font-size: 0.24rem;
      color: #161616;
      margin-bottom: 0.04rem;
    }
    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        font-weight: 400;
        font-size: 0.24rem;
        color: #161616;
        margin-bottom: 0.04rem;
        &.aTab{
          a{
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
