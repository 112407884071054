<!--  -->
<template>
  <div class="bd01" id="bd01" v-if="!isMobileBrowser">
    <div class="bd01Con">
      <!-- <aside class="start-wrapper">
        <div class="start-item start-item1"></div>
        <div class="start-item start-item2"></div>
      </aside> -->
      <Headers @download="onDownload" :channel="channel"></Headers>
      <bgImg></bgImg>
      <swiperTop :wallpaperList="wallpaperList" v-if="wallpaperList"></swiperTop>
      <div class="title">
        <div class="titleImg">
          <img src="../assets/images/bd01/titleBg.png" />
        </div>
        <downLoad @download="onDownload" :channel="channel" class="down"></downLoad>
      </div>
      <tagModel @download="onDownload" :channel="channel"></tagModel>
      <swiperCon @download="onDownload" :channel="channel"></swiperCon>
      <tool @download="onDownload" :channel="channel"></tool>
      <div class="footerBg"></div>
      <!-- https://www.apumao.net.cn/ 显示 -->
      <SFooter v-if="!isUrlVip" />
      <!-- https://www.apumao.vip/ 显示 -->
      <SFooterVip v-else></SFooterVip>
    </div>
  </div>
  <mainMobile :channel="channel" v-else></mainMobile>
</template>

<script>
import Headers from "../components/bd01/header.vue";
import bgImg from "../components/bd01/bgImg.vue";
import swiperTop from "../components/bd01/swiperTop.vue";
import downLoad from "../components/bd01/downLoadBtn.vue";
import tagModel from "../components/bd01/tagModel.vue";
import swiperCon from "../components/bd01/swiperCon.vue";
import tool from "../components/bd01/tool.vue";
import SFooter from "@/components/SFooter/foot2";
import SFooterVip from "@/components/SFooter/foot3";
import { apiBanner, apiSelected, apiBeautify, apiDownload } from "@/api/channel";
import mainMobile from "../components/mainMobile.vue";
export default {
  name: "",
  components: {
    Headers,
    bgImg,
    swiperTop,
    downLoad,
    tagModel,
    swiperCon,
    tool,
    SFooter,
    SFooterVip,
    mainMobile,
  },
  data() {
    return {
      isMobileBrowser: false,
      maoBaidu: ["88092670231123", "88092670231124", "88092670231125", "88092670231126", "3557842227"], //猫百度
      mao360: ["15170001125", "15170001126"], //360猫
      channel: "20210426043",
      wallpaperList: null,
    };
  },
  computed: {},
  created() {
    this.getMobileBrowser()
    if(!this.isMobileBrowser){
      this.getBanner();
      this.getisUrlVip();
    }
    this.$nextTick(() => {
      document.querySelectorAll(".is-baidu").forEach((item) => item.setAttribute("data-agl-cvt", 6));
    });
  },
  mounted() {
    if (window.location.href.indexOf("apumao.vip") > -1 || this.maoBaidu.indexOf(this.$route.query.n) > -1) {
      window._agl = window._agl || [];
      (function () {
        window._agl.push(["production", "_f7L2XwGXjyszb4d1e2oxPybgD"]);
        (function () {
          var agl = document.createElement("script");
          agl.type = "text/javascript";
          agl.async = true;
          agl.src = "https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD";
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(agl, s);
        })();
      })();
    } else {
      (function (b, a, e, h, f, c, g, s) {
        b[h] =
          b[h] ||
          function () {
            (b[h].c = b[h].c || []).push(arguments);
          };
        b[h].s = !!c;
        g = a.getElementsByTagName(e)[0];
        s = a.createElement(e);
        s.src = "//s.union.360.cn/" + f + ".js";
        s.defer = !0;
        s.async = !0;
        g.parentNode.insertBefore(s, g);
      })(window, document, "script", "_qha", 555180, false);
    }
  },
  methods: {
    getMobileBrowser() {
      let type = this.getVersion();
      if (type == 0) {
        this.isMobileBrowser = this.isMobile();
      }
    },
    getisUrlVip() {
      if (window.location.href.indexOf("apumao.vip") > -1 || this.maoBaidu.indexOf(this.$route.query.n) > -1) {
        this.isUrlVip = true;
      } else {
        this.isUrlVip = false;
      }
    },
    async onDownload() {
      let vType = this.getVersion();
      if (vType == 0) {
        //猫百度
        const { n: channel, name } = this.$route.query;
        const resp = await apiDownload({ zsdd: channel || this.channel });
        window.location.href = resp.data.url;
        window._czc.push(["_trackEvent", "下载", "下载按扭", "猫百度"]);
      } else if (vType == 1) {
        //360
        const { n: channel, name, qhclickid: qhclickid } = this.$route.query;
        const resp = await apiDownload({
          zsdd: channel || this.channel,
          // qhclickid: qhclickid,
          // type: "apumao",
        });
        window.location.href = resp.data.url;
        window._czc.push(["_trackEvent", "下载", "下载按扭", "360猫"]);
      }
    },
    //获取版本  type 0 猫百度www.apumao.vip   1  360猫 apumao.net.cn
    getVersion() {
      let urlHref = window.location.href;
      let type = 0;
      if (urlHref.indexOf("apumao.vip") > -1) {
        type = 0;
      } else if (urlHref.indexOf("apumao.net.cn") > -1) {
        type = 1;
      }
      return type;
    },
    // 判断是不是移动端
    isMobile() {
      const UA = window.navigator.userAgent.toLowerCase();
      let ios = UA.match(/iphone/i) == "iphone" || UA.match(/ipad/i) == "ipad" ? !0 : !1,
        adr = UA.match(/android/i) == "android" ? !0 : !1;
      return ios || adr;
    },
    async getBanner() {
      const { n: channel, name } = this.$route.query;
      this.wallpaperList = (await apiBanner({ channel, name })).data;
    },
  },
};
</script>

<style lang="scss" scoped>
.conSwiper {
  position: relative;
  z-index: 9;
}
.start-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.6;
  pointer-events: none;
}
.start-item {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/bd01/liz.png");
}
.start-item1 {
  animation: animStar1 80s linear infinite;
}
.start-item2 {
  animation: animStar2 80s linear infinite;
}
@keyframes animStar1 {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes animStar2 {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.bd01 {
  background: #000000;
  overflow-x: hidden;
  position: relative;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    /* background: #EDEDED; */
  }

  .bd01Con {
    width: 100%;
    height: auto;
    position: relative;
    display: inline;
    left: 0;
    top: 0;
    float: left;
  }
  .title {
    width: 100%;
    position: absolute;
    top: 686px;
    margin-top: 2px;
    .titleImg {
      margin: 0 auto;
      position: relative;
      width: 1193px;
      &::before {
        width: 25px;
        height: 6px;
        background: #2351ff;
        border-radius: 0px 0px 0px 0px;
        content: "";
        position: absolute;
        left: -77px;
        bottom: 0;
      }
      &::after {
        width: 25px;
        height: 6px;
        background: #2351ff;
        border-radius: 0px 0px 0px 0px;
        right: -25px;
        top: 17px;
        content: "";
        position: absolute;
      }
    }
    .down {
      margin: 0 auto;
      margin-top: 20px;
    }
  }
  .footerBg {
    position: absolute;
    left: 50%;
    width: 1512px;
    height: 370px;
    margin-left: -756px;
    background: url("../assets/images/bd01/footerBg.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    pointer-events: none;
  }
  ::v-deep(.footer) {
    background: rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    li {
      color: #fff !important;
    }
  }
}
</style>
