<template>
  <div>
    <a :class="['download3', type, { 'is-baidu': isBaidu }, { 'is-qihu': isQihu }, { 'is-360': is360 }]" title="点击下载" :href="apkurl">
      <i class="after"></i>
      点击下载
    </a>
  </div>
</template>
<script>
export default {
  props: ["type", "apkurl", "channel"],
  data() {
    return {};
  },
  methods: {
    download() {},
  },
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    is360() {
      return this.channel == 15170001125;
    },
    isQihu() {
      return this.channel == 20200707006;
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.download3 {
  width: 2.18rem;
  height: 0.72rem;
  background: #ffffff;
  border-radius: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.28rem;
  color: #158cf2;
  position: relative;
  .after {
    background: url("../assets/images/mobile/2024/down.png") no-repeat;
    background-size: 100% 100%;
    width: 0.34rem;
    height: 0.34rem;
    margin-right: 0.1rem;
  }
}
</style>
